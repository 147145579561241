import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Component/Layout';
import { Home } from './Component/Home';
import { Registration } from './Component/Registration';
import LoginWrapper from './Component/LoginWrapper';
import AktenzeichenWrapper from './Component/AktenzeichenWrapper';

import './App.css'
import { Admincenter } from './Component/Admincenter';
import { Impressum } from './Component/Impressum';
import { HowTo } from './Component/HowTo';
import { Kontakt } from './Component/Kontakt';
import { Dataprotection } from './Component/Dataprotection';
import { Welcome } from './Component/Welcome';
import {IhrAnwalt} from "./Component/IhrAnwalt";
import Validation from "./Component/Validation";
import {DataProtectionRules} from "./Component/DataProtectionRules";
import {Revolcationrules} from "./Component/Revolcationrules";

export default class App extends Component {
    static displayName = App.name;

    modal = true;
    toggle = () => this.setModal(!this.modal);

    setModal = (e:boolean) => {
        this.modal = e;
        return e
    }

    render() {
        return (
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/willkommen" element={<Welcome />} />
                    <Route path="/registrieren" element={<Registration />} />
                    <Route path="/anmelden" element={<LoginWrapper />} />
                    <Route path="/anmelden/:fallnummer" element={<LoginWrapper />} />
                    <Route path="/aktenzeichen" element={<AktenzeichenWrapper />} />
                    <Route path="/admincenter" element={<Admincenter />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/wastun" element={<HowTo />} />
                    <Route path="/kontakt" element={<Kontakt />} />
                    <Route path="/datenschutz" element={<Dataprotection />} />
                    <Route path="/anwalt" element={<IhrAnwalt />} />
                    <Route path="/validate/:vt" element={<Validation/>} />
                    <Route path="/datenschutzbelehrung" element={<DataProtectionRules/>}/>
                    <Route path="/widerrufsbelehrung" element={<Revolcationrules/>}/>
                </Routes>
            </Layout>

        );
    }
}

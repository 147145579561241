import { Component } from "react";
import { Container } from "reactstrap";

export class Kontakt extends Component {
    render() {
        return <Container style={{ backgroundImage: 'url("/Images/Kontakt.png")', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
            <h1>Kontakt</h1>
            <p><a href="mailto:post@frohnecke.li">post@frohnecke.li</a><br />
            Industriering 40, FL-9491 Ruggell</p>

            <p>Haben Sie Fragen zum Impfopferschutz?<br />
            Hinterlassen Sie eine Nachricht und ich melde mich zeitnah bei Ihnen zurück!</p>

            <p>Advocatur Dr. Frohnecke GmbH<br />
                Industriering 40<br />
                FL-9491 Ruggell<br />
                <a href="mailto:post@frohnecke.li">post@frohnecke.li</a></p>

        </Container>
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component<{ children?: React.ReactNode }, {}> {
    static displayName = Layout.name;

    render() {
        return <div>
            <NavMenu />
            <Container>
                {this.props.children}
                <footer>
                    <hr />
                    <Row>
                        <Col md={3}>&copy; 2022 by Impfopferschutz</Col>
                        <Col md={3}><Link to="/impressum">Impressum</Link></Col>
                        <Col md={3}><Link to="/datenschutz">Datenschutz</Link></Col>
                        <Col md={3}><Link to="/kontakt">Kontakt</Link></Col>
                    </Row>
                </footer>
            </Container>
        </div>;
    }
}

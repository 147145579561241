import { Component } from "react";
import { Container } from "reactstrap";

export class DataProtectionRules extends Component {

    render() {
        return <Container>
            <h2>DATENSCHUTZHINWEISE</h2>
            <h3><ol>
                <li>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten
                    </li>
            </ol></h3>
<p>
    Diese Datenschutzhinweise gelten für die Datenverarbeitung durch:
</p>
            <p>
                <b>Verantwortlicher:</b><br/>
            Advocatur Dr. Frohnecke GmbH<br/>
            Ansprechpartner: Rechtsanwalt Dr. iur. Eberhard Frohnecke<br/>
            Industriering 40<br/>
            FL-9491 Ruggell<br/>
            </p>
<p>
            E-Mail: <a href="mailto:post@frohnecke.li">post@frohnecke.li</a>
</p><p>
            Telefon: 	<a href="tel:00423 2202142">00423 2202142</a>
        </p><p>
            Die betriebliche Datenschutzbeauftragte ist wie folgt erreichbar:<br/>
            Max von Breitenstein® Data Protect GmbH<br/>
            <a href="mailto:post@maxvonbreitenstein.de">post@maxvonbreitenstein.de</a><br/>
            <a href="https://www.maxvonbreitenstein.de" target="_blank">https://www.maxvonbreitenstein.de</a><br/>
        </p><p>
            <h3><ol start={2}><li>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck und deren Verwendung</li></ol></h3>
            Wenn Sie uns beauftragen, erheben wir folgende Informationen:<br/>
            <ul>
                <li>Anrede, Vorname, Nachname,</li>
            <li>eine gültige E-Mail-Adresse,</li>
            <li>Anschrift,</li>
            <li>Telefonnummer (Festnetz und/oder Mobilfunk),</li>
            <li>Geburtsdatum,</li>
            <li>Rechtschutzversicherung,</li>
            <li>Informationen, die für die Geltendmachung und Verteidigung Ihrer Rechte im Rahmen des Auftrags notwendig sind.</li>
            </ul>
        </p><p>
            Die Erhebung dieser Daten erfolgt,<br/>
            <ul>
            <li>um Sie als unseren Mandanten identifizieren zu können;</li>
            <li>um Sie angemessen anwaltlich beraten und vertreten zu können;</li>
            <li>um sicherzustellen, ob besondere Vorgaben nach Art. 8 DSGVO einzuhalten sind;</li>
            <li>zur Korrespondenz mit Ihnen;</li>
            <li>zur Rechnungsstellung;</li>
            <li>zur Abwicklung von evtl. vorliegenden Haftungsansprüchen sowie der Geltendmachung etwaiger Ansprüche gegen Sie.</li>
            </ul>
        </p><p>
            Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b DSGVO zu den genannten Zwecken für die angemessene Bearbeitung des Vertrags und für die beidseitige Erfüllung von Verpflichtungen aus dem Vertrag erforderlich.
            Die für die Durchführung des Vertrags von uns erhobenen personenbezogenen Daten werden bis zum Ablauf der gesetzlichen Aufbewahrungspflicht gespeichert und danach gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (aus HGB, BRAO, StGB oder AO) zu einer längeren Speicherung verpflichtet sind oder Sie in eine darüberhinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
        </p><p>

            <h3><ol start={3}><li> Weitergabe von Daten an Dritte</li></ol></h3>
            Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
            Soweit dies nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist, werden Ihre personenbezogenen Daten an Dritte weitergegeben. Hierzu gehört insbesondere die Weitergabe an Verfahrensgegner und deren Vertreter (insbesondere deren Rechtsanwälte) sowie Gerichte und andere öffentliche Behörden zum Zwecke der Korrespondenz sowie zur Geltendmachung und Verteidigung Ihrer Rechte. Die weitergegebenen Daten dürfen von dem Dritten ausschließlich zu den genannten Zwecken verwendet werden.
            Das Anwaltsgeheimnis bleibt unberührt. Soweit es sich um Daten handelt, die dem Anwaltsgeheimnis unterliegen, erfolgt eine Weitergabe an Dritte nur in Absprache mit Ihnen.
        </p><p>
            <h3><ol start={4}><li>Betroffenenrechte</li></ol></h3>
            Sie haben das Recht:<br/>
            <ul>
            <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;
            </li>
            <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
            </li>
            <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
            </li>
            <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
            </li>
            <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
            <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und</li>
            <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.</li>
            </ul>
        </p><p>

            <h3><ol start={5}><li>Widerspruchsrecht</li></ol></h3>
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <a href="mailto:post@maxvonbreitenstein.de">post@maxvonbreitenstein.de</a>
        </p><p>
            <h3><ol start={6}><li>Beschwerderecht bei der Aufsichtsbehörde</li></ol></h3>
            Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
        </p><p>
            Die Anschrift der für unsere Kanzlei zuständigen Aufsichtsbehörde lautet:
        </p><p>
            Datenschutzstelle Fürstentum Liechtenstein:<br/>
            <a href="https://www.datenschutzstelle.li" target="_blank">https://www.datenschutzstelle.li</a><br/>
            Frau Dr. Marie-Louise Gächter-Alge<br/>
            Städtle 38<br/>
            FL-9490 Vaduz<br/>
            <a href="mailto:Info.dss@llv.li">Info.dss@llv.li</a>
            <br/>
        </p><p>

            <b>Einwilligungserklärung</b><br/>
            Die vorgenannten Hinweise zum Datenschutz gemäß Art. 13 DS GVO nebst meinen Betroffenenrechten habe ich erhalten und zur Kenntnis genommen. Dies habe ich mit dem Click bei der Anmeldung und der Bestätigung des Validierungslink bestätigt.
        </p>
        </Container>
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container,  Row } from 'reactstrap';

import './Home.css'

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return <Container className="homeback">
            <Row>
                <Col md={6}>
                    <br/>
                    <div className="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/1K4mrqKmXak?autoplay=1"
                                title="Ihr Anwalt" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6 }>
            <span style={{ fontSize: '25pt', color: '#000000', fontWeight: 'bold', textAlign: 'center' }}>
                <br />Schmerzensgeld&shy;ansprüche bereits bei lediglich fehlerhafter Aufklärung durch den impfenden Arzt:<br />Rechtsanwalt<br />Dr. Eberhard Frohnecke<br />sichert Ihr Recht.
                <br />
                        <Link className="btn btn-primary" to="/willkommen">So geht es! (bitte anklicken)</Link>
                    </span>
                    </Col>
                </Row>
        </Container>
    }
}

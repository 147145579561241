import React, {Component} from "react";
import {Container} from "reactstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import apiService from "../Service/ApiService";

const withRouter = <Props extends WithRouterProps>(
    Component: React.ComponentType<Props>
) => {
    return (props: Omit<Props, keyof WithRouterProps>) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();

        return (
            <Component
                {...(props as Props)}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    };
};


class Validation extends Component<WithRouterProps, ValidationState> {

    constructor(props:WithRouterProps) {
        super(props);
        this.state = (
            {
                isValidate: false,
                firstLoad: true,
                token: ""
            }
        )
    }

    componentDidMount() {
        this.ValidateActivationToken(this.props.params.vt)
    }

    async ValidateActivationToken(token:string){
        var response = await apiService.fetch("/validation/"+token, {
            method: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.ok) {
            this.setState(
                {
                    isValidate: true,
                    firstLoad: false,
                    token:token
                }
            )
        } else {
            this.setState(
                {
                    isValidate: false,
                    firstLoad: false,
                    token:"null"
                }
            )
        }
    }


    render() {

        return <Container>
                    <h1 hidden={!this.state.firstLoad}>Aktivierung läuft</h1>
                    <h1 hidden={!this.state.isValidate}>Akte wurde aktiviert</h1>
                    <h1 hidden={this.state.isValidate}>Akte wurde nicht aktiviert</h1>
                </Container>
    }

}

export default withRouter(Validation)


interface WithRouterProps {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
}

interface ValidationState{
    isValidate:boolean;
    firstLoad: boolean;
    token:string;
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

export class HowTo extends Component {
    render() {
        return <Container>
            <h1>Was Sie tun müssen</h1>
            <br/>
            <p>
                <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/s7-NoCLjsmY?autoplay=1"
                            title="Willkommen bei Impfopferschutz" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </p>
            <br/>
            <p>Was Sie tun müssen, um Ihre Ansprüche geltend zu machen (Checkliste!):</p>
            <p>Ein Mandatsauftrag an die Advocatur Dr. Frohnecke GmbH wird durch Sie nur erteilt und hat auch nur dann Aussicht auf Erfolg, wenn Sie die folgende Eingabemaske vollständig und wahrheitsgemäß befüllen. Sie erhalten dann eine E-Mail zur Bestätigung dessen und haben zugleich das Ihnen damit überreichte Vollmachtsformular entweder auszudrucken, zu unterzeichnen, einzuscannen und zurückzumailen, bzw. in Ihrem Konto hochzuladen oder, um das Schriftlichkeitsgebot zu befolgen, Ihre bereits zuvor eingescannte Unterschrift dort einzusetzen. Dasselbe gilt für die überreichte Widerrufsbelehrung. Die Datenschutzbelehrung erfolgt ebenfalls mit diesem ersten Schreiben. Das Mandatsverhältnis kommt erst dann zustande, wenn wir Ihnen dies entsprechend durch Überreichung bereits für Sie geführter Korrespondenz bestätigen. Dies erfolgt durch eine E-Mail mit einem Link. Folgen Sie bitte dem Link und richten sich zu Ihrem Vorgang ein Postfach ein. Immer wenn sich in Ihrem Postfach irgendetwas ändert, erhalten Sie eine Nachricht per E-Mail.</p>
            <p>Für den Fall, dass Sie über keine Kostendeckung einer hinter Ihnen stehenden Rechtsschutzversicherung verfügen, tragen Sie das Verfahrensrisiko allein. Dieses bemisst sich zunächst nach dem Gegenstandswert, meist das, was Sie als Schmerzensgeld fordern. Bei Ihrer Einschätzung, welches Schmerzensgeld pro Impfung (also derzeit bis zu dreimal!) als von dem Gericht angemessen erachtet werden könnte, helfen wir Ihnen mit der folgenden Rechtsprechungsübersicht:</p>
            <p>
                <ol>
                    <li>€ 5.000,00 (inklusive Inflationsausgleich heute ca. 5.100,00) [bis zu dreimal!]Wegen fehlender Aufklärung rechtswidrige und unzureichend geplante Revisionsoperation im Zusammenhang mit einer Brustvergrößerung die Zuerkennung eines Schmerzensgeldes von € 5.000,00 für eine ohne hinreichende Einwilligung vorgenommene Schönheitsoperation entspricht den in der Rechtsprechung in vergleichbaren Fällen zuerkannten Schmerzensgeldern (Brandenburgisches Oberlandesgericht, Urteil vom 25.05.2021 - 12 U 124/20).</li>
                    <li>€ 10.000,00 (inklusive Inflationsausgleich heute ca. € 14.000,00) [bis zu dreimal!] Verstoß gegen Aufklärungspflicht. Der lapidare Hinweis in dem Einverständniserklärungsformular, es handele sich um einen Eingriff in die Unversehrtheit, der zu gesundheitlichen Schäden führen könnte, wird den Anforderungen an eine ausreichende Aufklärung nicht gerecht (LG Koblenz, Urteil vom 24.01.2006 - 10 O 176/04).</li>
                    <li>€ 30.000,00 (inklusive Inflationsausgleich heute ca. € 35.000,00) [bis zu dreimal!] Aufklärungsmangel über die möglichen Risiken im Zusammenhang mit einer Hüftoperation (OLG Nürnberg, Urteil vom 30.04.2015 - 5 U 2282/13).</li>
                </ol>
            </p>
            <p>Bitte bedenken Sie bei der Bemessung des von ihnen verlangten Schmerzensgeldes, dass Sie nicht nur mit einer Nadel körperlich leicht verletzt wurden, sondern vielmehr ein nicht ausreichend getesteter Impfstoff in Ihren Körper verbracht wurde, dessen mittelfristige sowie langfristige Folgen auf Ihren Organismus nicht ausreichend bekannt war. Dies kommt im juristischen Sinne einer Vergiftung nahe.</p>
            <p>Anhand der entsprechenden im Internet leicht recherchierbaren Tabellen nach RVG 2021 können Sie das finanzielle Risiko selbst ermitteln und dann einschätzen. Ein Schmerzensgeldanspruch für eine (!) fehlerhafte Aufklärung bei einer Impfung kann ohne weiteres bereits € 5.000,00 betragen. Sind Sie dreimal so geimpft worden, sind es bereits wenigstens € 15.000,00. Impffolgen oder -schäden sind hierfür nicht erforderlich. Im Lichte der oben weiter zitierten Rechtsprechung ist damit schnell ein Schmerzensgeld von bis zu                         € 105.000,00 möglich. Eine gefestigte Rechtsprechung bzgl. der Höhe des Schmerzensgeldes besteht jedoch noch nicht in Bezug auf diese Impfmaßnahmen! Zum Aufklärungsmangel kommt stets eine Behandlung, wie oben z.B. eine Hüftoperation. Bedenken Sie dabei bitte, dass das Einbringen eines Stoffes in einen Körper eine ebensolche Behandlung darstellt, die unter Umständen noch viel gesundheitsbeeinträchtigendere Folgen für Ihren Organismus haben kann, wie eine (missglückte) Hüftoperation.</p>
            <p>Sobald das oben Gesagte erledigt ist, schreiben wir den jeweiligen Behandler an und setzen ihm eine Frist zur Zahlung des von Ihnen uns wunschgemäß angegebenen Schmerzensgeldes auf das von Ihnen in der folgenden Eingabemaske ebenso angegebene Konto (IBAN). Dabei muss es sich um Ihr Konto handeln!</p>
            <p>Nach Ablauf der Frist erinnern wir Sie per E-Mail, Ihr Konto auf einen Zahlungseingang zu überprüfen. Wir bitten dann um entsprechende Beantwortung. Sollte kein oder nicht der geforderte Zahlungseingang festgestellt werden, greift die Bedingung zum Klageauftrag.</p>
            <p>Wir fertigen dann anhand der von Ihnen übermittelten Daten die entsprechende Klage. Für den Fall, dass eine Rechtschutzversicherung mit eingebunden ist, werden wir erst nach Kostendeckungszusage dieser gemäß des zwischen Ihnen und der Rechtschutzversicherung bestehenden Vertrages und der Bedingungen weiter aktiv. Bitte beachten Sie hierbei unbedingt, dass wir keine Korrespondenz mit ihrer Rechtsschutzversicherung führen. Die Kostendeckungszusage müssen Sie, gern unter Hinweis auf diese Seite, dort einholen. Im Falle einer Klage erhalten Sie dann von dem Gericht, ggf. über uns, eine Gerichtskostenvorschussrechnung, die entweder von Ihnen oder von Ihrer Rechtsschutzversicherung direkt an das Gericht zu überweisen ist! Bitte stimmen Sie sich insoweit mit Ihrer Rechtsschutzversicherung ab. Sobald das Gericht die Gerichtskostenanforderung überreicht, senden wir diese an Sie. Die Klage wird immer erst dann dem Gegner zugestellt, wenn der Gerichtskostenvorschuss bei Gericht vollständig eingezahlt ist.</p>
            <p>Bitte beachten Sie, dass in allen Fällen bei Beauftragung von Rechtsanwälten immer der Mandant der Kostenschuldner ist, die Rechtschutzversicherung nur aufgrund eines Vertrages mit dem Mandanten verpflichtet ist, ihm diese Kosten ganz oder teilweise zu erstatten. Den gesamten Vorgang und auch Schriftverkehr können Sie durch eine von uns Ihnen gewährte Zugangsmöglichkeit zu Ihrer Online-Akte jederzeit einsehen, somit verfolgen und auch leicht Ihrer Rechtsschutzversicherung zur Verfügung stellen. Sie können die dort gespeicherten Dokumente auch jederzeit herunterladen oder ausdrucken. Es wird von uns eine höchstmögliche Transparenz verfolgt.</p>
            <p>Sind Sie betreffend jeder einzelnen Impfung von dem Behandler (Arzt/Ärztin) mündlich aufgeklärt worden?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Wenn ja: sind Sie über die Risiken des Eingriffs durch die Injektion aufgeklärt worden?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Sind Sie über Art und Umfang der Durchführung der Impfung aufgeklärt worden?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Sind Sie darüber aufgeklärt worden, dass es zu der Impfung ggf. Behandlungsalternativen gibt?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Ist Ihnen klar und deutlich erläutert worden, dass es gesundheitliche Folgen der Impfung gibt?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Ist Ihnen klar und deutlich erläutert worden, dass Sie nach der Impfung bestimmte Verhaltensmaßregeln befolgen müssen?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Sind Sie gefragt worden, ob Sie die Belehrung verstanden haben?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Haben Sie diese umfassenden Belehrungsinhalte durch Ihre Unterschrift bestätigt?
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Wenn Sie alle Fragen mit Ja beantworten, besteht voraussichtlich kein Schmerzensgeldanspruch wegen Aufklärungsmangels!</p>
            <p>Wenn Nein oder alle Ja, aber nur schriftlich: Sind Sie durch ein Formular belehrt worden, welches Sie unterzeichnen mussten? (hier in jedem Fall Schmerzensgeldanspruch)
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Haben Sie mit Ihrer Unterschrift (jeweils) auf die Belehrung verzichtet? (hier in jedem Fall Schmerzensgeldanspruch)
            <Row>
                <Col md={1}>
                    <input type="checkbox" /> Ja
                </Col>
                <Col md={2}>
                    <input type="checkbox" /> Nein
                </Col>
                </Row></p>
            <p>Sind Sie noch in der Lage, dieses Formular hochzuladen und uns zur Überprüfung zur Verfügung zu stellen?<br /><Link className="btn btn-primary" to="/registrieren">Anspruch stellen</Link></p>

            <p><b>Hinweis:</b><br />Eine Belehrung nur mittels Formulars ist nach §§ 630 d Abs. 2., 630 e BGB unwirksam! Sollten Sie einen Verzicht auf Belehrung unterschrieben haben, ist das ebenfalls unwirksam (vgl. z.B. § 630 d Abs. 2 BGB und § 309 Nr. 12 b BGB)! Es besteht dann in jedem Fall ein Schmerzensgeldanspruch!</p>

        </Container>
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Container} from 'reactstrap';

export class Welcome extends Component {
    render() {
        return <Container>
            <h1>Willkommen beim Impfopferschutz!</h1>
            <p>Hier geht es nicht um Politik oder irgendeine Überzeugung – hier geht es ausschließlich um Ihr Recht!</p>
            <p><b>Sie haben Schmerzensgeldansprüche bereits bei lediglich fehlerhafter Aufklärung durch den impfenden Arzt:</b></p>
            <Card>
                <CardBody className="graycard">
                    Wer ohne oder ohne eine wirksame Aufklärung (vermeintlich) gegen COVID-19 geimpft wurde und keine gesundheitlichen Folgen verspürt hat, hat ebenso einen Anspruch auf immateriellen Schadensersatz (sog. Schmerzensgeld) gegen den ihn impfenden Behandler (Arzt) wie jemand, der infolge der Impfung z.B. eine Herzmuskelentzündung oder andere typische Impfschäden zu erleiden hatte. Pro Impfvorgang kann so ein Schmerzensgeld in Höhe von € 5.000,00 bis nach oben offen entstanden und für Sie durchsetzbar sein.
                </CardBody>
            </Card>

            <p>Im Lichte der Gesetzgebung existieren daher 3 Gruppen von Impfopfern:</p>
            <ol>
                <li>Die Menschen, die sich aufgrund eines Aufklärungsmangels haben impfen lassen, aber hierdurch keine gesundheitliche Beeinträchtigung verspürt haben.</li>
                <li>Die Menschen, die sich aufgrund eines Aufklärungsmangels haben impfen lassen, und infolge der Impfung Leid erfuhren, wie z.B. recht häufig myocarditis.</li>
                <li>Die Erben eines Menschen, der sich aufgrund eines Aufklärungsmangels hat impfen lassen und daran verstorben ist („plötzlich und unerwartet!“).</li>
            </ol>
            <p>Sollten Sie zu den glücklichen Menschen gehören, die der 1. Gruppe zuzuordnen sind, und sie wollen ihr gutes Recht geltend machen, dann ist es ausreichend, wenn Sie die folgende Eingabemaske vollständig und wahrheitsgemäß ausfüllen. Hierdurch würde dann ein Mandatsverhältnis mit den für die Seite Impfopferschutz tätigen Rechtsanwälten der Advocatur Dr. Frohnecke GmbH im Fürstentum Liechtenstein entstehen, die in Deutschland und auch vor den deutschen Gerichten für Sie tätig werden. Der Geschäftsführer der Advocatur Dr. Frohnecke GmbH ist ein in Deutschland bei der Rechtsanwaltskammer für den Oberlandesgerichtsbezirk Oldenburg zugelassener Rechtsanwalt mit mehr als 23 Jahren forensischer Erfahrung und zweifacher Fachanwalt. Herr Dr. Frohnecke verfügt hierneben über eine weitere Zulassung bei der Liechtensteinischen Rechtsanwaltskammer.</p>
            <p><Link className="btn btn-primary" to="/registrieren">Anspruch stellen</Link></p>

            <p>Sollten Sie zu der Gruppe 2 oder 3 gehören, empfehlen wir Ihnen, mit den Rechtsanwälten Dr. Frohnecke & Partner mbB in Osnabrück unter post@ffos.de oder 0541/40694-0 für eine individuelle Beratung Kontakt aufzunehmen.</p>


        </Container>
    }
}

import React, { Component } from 'react';
import {Container} from 'reactstrap';

export class IhrAnwalt extends Component {
    render() {
        return <Container>
            <h1>Ihr Anwalt</h1>

            <p>
                Rechtsanwalt Dr. iur. Eberhard Frohnecke ist Fachanwalt für Versicherungsrecht und zugleich Fachanwalt für Gewerblichen Rechtsschutz. Nach der Zulassung zur Rechtsanwaltschaft im Jahr 1999 war er zunächst Gesellschafter einer anderen Rechtsanwaltskanzlei. 2002 gründete er mit seiner Ehefrau Dr. iur. Stefanie Frohnecke die Rechtsanwaltskanzlei Dr. Frohnecke & Partner. 2022 gründete er die Advocatur Dr. Frohnecke GmbH mit Sitz in Ruggell im Fürstentum Liechtenstein.
            </p>
            <p>
                <h3>Werdegang</h3>
                <ul>
                    <li>1990 Erfolgreicher Abschluss zum Bankkaufmann vor der Hanseatischen Handelskammer zu Hamburg.</li>
                    <li>1990 - 1994 Studium der Rechtswissenschaften mit wirtschaftswissenschaftlicher Zusatzausbildung an der Universität zu Osnabrück</li>
                    <li>1995 Erstes Juristisches Staatsexamen</li>
                    <li>1995 Diverse Praktika in namhaften Anwaltskanzleien</li>
                    <li>1996 - 1998 Referendariat im Landgerichtsbezirk Osnabrück</li>
                    <li>1997 - 1998 Promotionsstudium und Auslandspraktikum in Wien / Österreich</li>
                    <li>1998 Zweites Juristisches Staatsexamen</li>
                    <li>1999 Zulassung als Rechtsanwalt</li>
                    <li>Seit 2000 Vertreter vor dem Harmonisierungsamt für Binnenmarktangelegenheiten der Europäischen Union in Alicante/Spanien (EU-Markenamt).</li>
                    <li>2001 Ernennung zum Doktor der Rechtswissenschaften</li>
                    <li>2007 Fachanwalt für Versicherungsrecht</li>
                    <li>2009 Fachanwalt für Gewerblichen Rechtsschutz</li>
                </ul>
            </p>
            <p>
                <h3>Mitgliedschaften</h3>
                <ul>
                    <li>Mitglied in der Arbeitsgemeinschaft für Versicherungsrecht im Deutschen Anwaltverein e.V. (DAV)</li>
                    <li>Mitglied im DAV, Ortsverband Osnabrück Mitglied in GRUR (Deutsche Vereinigung für gewerblichen Rechtsschutz und Urheberrecht e.V.) Vertreter vor dem Deutschen Patent- und Markenamt (DPMA) sowie dem Amt der Europäischen Union für geistiges Eigentum (EUIPO)</li>
                    <li>Rechtsanwalt Dr. Eberhard Frohnecke ist Mitglied bei der Rechtsanwaltskammer in Oldenburg. Er ist ferner Mitglied bei der Liechtensteinischen Rechtsanwaltskammer.</li>
                </ul>
            </p>
        </Container>
    }
}
import React, {Component, FormEvent, useState} from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { RegstrationData, Gegner, Impfung, Mandant, FallNummer } from '../Model/RegistrationModels';
import apiService from '../Service/ApiService';
import { LabelWithText } from './Helpers/LabelWithText';

export class Registration extends Component<RegistrationProps, RegistrationState> {

    constructor(props: RegistrationProps) {
        super(props);

        this.state = {
            absendenBtn: false,
            isDatenschutz: false,
            isWiderspruch: false,
            isKorrekt: false,
            fallnummerCreated: "",
            activeTab: "videoTab",
            impfCenterIndex: 0,
            impfungIndex: 0,
            RegstrationData: {
                gegner: [{
                    emailadresse: "",
                    hausnummer: "",
                    herrFrau: "Herr",
                    iban: "",
                    land: "",
                    nachname: "",
                    ort: "",
                    plz: "",
                    strasse: "",
                    telefonnummer: "",
                    titel: "",
                    vorname: "",
                    uudi: "",
                    gegnerNr: 0
                }],
                impfungen: [{
                    uudi: "",
                    bezahlterBetrag: 0,
                    gegnerNr: 0,
                    impfDatum: "",
                    impfStoff: "",
                    impfZertifikatId: ""
                }],
                schmerzensGeldProImpfung: 5000,
                mandant: {
                    emailadresse: "",
                    hausnummer: "",
                    herrFrau: "Herr",
                    iban: "",
                    land: "",
                    nachname: "",
                    ort: "",
                    plz: "",
                    strasse: "",
                    telefonnummer: "",
                    titel: "",
                    vorname: "",
                    uudi: ""
                }
            }
        }
    }

    handleMandantChange = (e: FormEvent<HTMLInputElement>) => {
        var regData = this.state.RegstrationData;
        var mandant: any = regData.mandant;
        mandant[e.currentTarget.id] = e.currentTarget.value;
        this.setState({ RegstrationData: regData });
    }

    handleGegnerChange = (e: FormEvent<HTMLInputElement>) => {
        var regData = this.state.RegstrationData;
        var gegner: any = regData.gegner[this.state.impfCenterIndex];
        gegner[e.currentTarget.id] = e.currentTarget.value;
        this.setState({ RegstrationData: regData });
    }

    handleImpfChange = (e: FormEvent<HTMLInputElement>) => {
        var regData = this.state.RegstrationData;
        var impfung: any = regData.impfungen[this.state.impfungIndex];
        impfung[e.currentTarget.id] = e.currentTarget.value;
        this.setState({ RegstrationData: regData });
    }

    handleMoneyAmountChange = (e: FormEvent<HTMLInputElement>) => {
        var regData = this.state.RegstrationData;
        regData.schmerzensGeldProImpfung = e.currentTarget.valueAsNumber;
        this.setState({ RegstrationData: regData });
    }

    addNewImpfCenter() {
        var regData = this.state.RegstrationData;
        var currentIndex = regData.gegner.length;
        regData.gegner.push({
            emailadresse: "",
            hausnummer: "",
            herrFrau: "Herr",
            iban: "",
            land: "",
            nachname: "",
            ort: "",
            plz: "",
            strasse: "",
            telefonnummer: "",
            titel: "",
            vorname: "",
            uudi: "",
            gegnerNr: currentIndex
        });
        this.setState({ RegstrationData: regData, impfCenterIndex: currentIndex });
    }

    addNewImpfung() {
        var regData = this.state.RegstrationData;
        var currentIndex = regData.impfungen.length;
        regData.impfungen.push({
            uudi: "",
            bezahlterBetrag: 0,
            gegnerNr: 0,
            impfDatum: "",
            impfStoff: "",
            impfZertifikatId: ""
        });
        this.setState({ RegstrationData: regData, impfungIndex: currentIndex });
    }

    async registerImpfSchaden() {
        var regData = this.state.RegstrationData;

        if (!regData.impfungen[0].impfStoff) {
            alert("Bitte mindestens eine Impfung vollständig erfassen")
            return;
        }

        if (!regData.gegner[0].emailadresse) {
            alert("Bitte mindestens einen Impfarzt vollständig erfassen")
            return;
        }

        var result = await apiService.fetch("/registration", {
            body: JSON.stringify(regData),
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
        if (result.ok) {
            var fallnummer: FallNummer = await result.json();
            this.setState({
                isDatenschutz: false,
                isWiderspruch:false,
                isKorrekt: false,
                absendenBtn:false,
                fallnummerCreated: fallnummer.fallNummer,
                activeTab: "FallCreated",
                impfCenterIndex: 0,
                impfungIndex: 0,
                RegstrationData: {
                    gegner: [{
                        emailadresse: "",
                        hausnummer: "",
                        herrFrau: "Herr",
                        iban: "",
                        land: "",
                        nachname: "",
                        ort: "",
                        plz: "",
                        strasse: "",
                        telefonnummer: "",
                        titel: "",
                        vorname: "",
                        uudi: "",
                        gegnerNr: 0
                    }],
                    impfungen: [{
                        uudi: "",
                        bezahlterBetrag: 0,
                        gegnerNr: 0,
                        impfDatum: "",
                        impfStoff: "",
                        impfZertifikatId: ""
                    }],
                    schmerzensGeldProImpfung: 5000,
                    mandant: {
                        emailadresse: "",
                        hausnummer: "",
                        herrFrau: "Herr",
                        iban: "",
                        land: "",
                        nachname: "",
                        ort: "",
                        plz: "",
                        strasse: "",
                        telefonnummer: "",
                        titel: "",
                        vorname: "",
                        uudi: ""
                    }
                }
            });
        } else {
            alert("Anmeldung konnte nicht erfasst werden. Fehlermeldung: " + result.statusText);
        }
    }


    handleChangeKorrekt = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isKorrekt: e.target.checked });
    }

    handleChangeWiderspruch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isWiderspruch: e.target.checked });
    }
    handleChangeDatenschutz = (e: React.ChangeEvent<HTMLInputElement>) => {this.setState({ isDatenschutz: e.target.checked });}

    render() {



        return <div>
            <h1>Aktuell können keine weitere Mandate angenommen werden. </h1><h3>Bitte kommen Sie zu einem anderen Zeitpunkt wieder, wenn wir wieder Kapazitäten frei haben. <br/> Viele Dank für Ihr Verständnis</h3>
        </div>;
    }
}

interface RegistrationProps {

}

interface RegistrationState {
    absendenBtn: boolean;
    isDatenschutz: boolean;
    isWiderspruch: boolean;
    isKorrekt: boolean;
    RegstrationData: RegstrationData;
    activeTab: string;
    impfCenterIndex: number;
    impfungIndex: number;
    fallnummerCreated: string;
}
import { Component } from "react";
import { Container, Label } from "reactstrap";


export class LabelWithText extends Component<LabelWithTextProperties>{


    render() {
        return <div>
            <Label style={{ fontWeight: 'bold' }}>
                {this.props.label}:&nbsp;
            </Label>
            <Label>
                {this.props.text}
            </Label>
        </div>
    }
}

export interface LabelWithTextProperties {
    label: string;
    text: string | undefined;
}
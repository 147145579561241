import { Component } from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, InputGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Akte, MyDocumentType, MyDocument, PaymentStatus } from "../Model/RegistrationModels";
import apiService from "../Service/ApiService";
import { LabelWithText } from "./Helpers/LabelWithText";

export class Aktenzeichen extends Component<AktenzeichenProperties, AktenzeichenState>{

    constructor(props: AktenzeichenProperties) {
        super(props);

        this.state = {
            aktenzeichen: undefined,
            documentTypes: [],
            selectedFile: undefined,
            selectedDocType: "",
            paymentStati: [],
            selectedPaymentStati: "",
            activeTab: "persTab"
        }
    }

    componentDidMount() {
        this.loadDatas();
    }

    async loadDatas() {
        var docTypes = await apiService.GetDocumentTypes();
        var paymentStati = await apiService.GetPaymentStati();
        var response = await apiService.fetch("/aktenzeichen/akte");
        if (response.ok) {
            var akte = await response.json();
            this.setState({ aktenzeichen: akte, documentTypes: docTypes, paymentStati: paymentStati, selectedPaymentStati: akte.payInfoText, selectedDocType: docTypes.find(dt => dt.mandatoryUploadFile)?.documentType ?? "" });
        } else if (response.status == 401) {
            this.props.navigateFunction("/anmelden");
        } else {
            this.setState({ documentTypes: docTypes, paymentStati: paymentStati });
        }
    }

    async downloadFile(file: MyDocument) {
        var response = await apiService.fetch("/document/download", {
            method: "POST",
            body: JSON.stringify(file),
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        if (response.ok) {
            var fileName = file.downloadFileName;
            var blob = await response.blob();
            const newBlob = new Blob([blob], { type: 'application/pdf' });
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = fileName;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
        } else if (response.status == 401) {
            this.props.navigateFunction("/anmelden");
        }
    }

    async uploadFile() {
        if (!this.state.selectedFile) {
            alert("Bitte eine Datei auswählen.");
            return;
        }

        var formData = new FormData();
        formData.append("file", this.state.selectedFile!);
        formData.append("document_typ", this.state.selectedDocType);

        var result = await apiService.fetch("/document/upload", {
            method: "POST",
            body: formData
        });

        if (result.ok) {
            this.loadDatas();
        } else {
            alert(result.status + " " + result.statusText)
        }
    }

    savePaymentStati = async () => {
        var result = await apiService.fetch("/aktenzeichen/payed", {
            method: "POST",
            body: "{\"zahlungsText\": \"" + this.state.selectedPaymentStati + "\"}",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        if (result.ok) {
            this.loadDatas();
        } else {
            alert(result.status + " " + result.statusText)
        }
    }

    handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedDocType: e.currentTarget.value });
    }

    handlePaymentStatiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedPaymentStati: e.currentTarget.value });
    }

    handleFileSelected = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt == null) {
            return;
        }

        if (evt.currentTarget == null) {
            return;
        }

        if (evt.currentTarget.files == null) {
            return;
        }

        if (evt.currentTarget.files.length == 0) {
            return;
        }

        var file = evt.currentTarget.files[0];
        if (!file.name.endsWith(".pdf")) {
            alert("Es sind nur PDF Dateien erlaubt.");
            evt.preventDefault();
            return;
        }

        this.setState({
            selectedFile: file
        })
    }

    render() {
        return <Container>
            <h1>Ihr Aktenkennzeichen</h1>
            <h2>Übersicht:&nbsp;</h2>

            <h3>Aktenstatus:&nbsp;</h3>
            <Row>
                <Col md={3}>
                    <LabelWithText label="Aktenzeichen" text={this.state.aktenzeichen?.aktenZeichen} />
                </Col>
                <Col md={3}>
                    <LabelWithText label="Status" text={this.state.aktenzeichen?.aktenStatus} />
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="payInfoText" style={{ fontWeight: 'bold' }}>
                            Status Bezahlung Forderung
                        </Label>
                        <InputGroup>
                            <Input
                                id="payInfoText"
                                name="payInfoText"
                                type="select"
                                onChange={this.handlePaymentStatiChange}
                            >
                                <option>Keine Zahlungsinformation vorhanden</option>
                                {
                                    this.state.paymentStati.map(stati => <option>{stati.zahlungsText}</option>)
                                }
                            </Input>
                            <Button onClick={() => { this.savePaymentStati() }}>Speichern</Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>

            <Nav tabs>
                <NavItem key={"persTab"}>
                    <NavLink className={this.state.activeTab == "persTab" ? "active" : ""} onClick={() => this.setState({ activeTab: "persTab" })}> Personalien</NavLink>
                </NavItem>
                <NavItem key={"impfzenterTab"}>
                    <NavLink className={this.state.activeTab == "impfzenterTab" ? "active" : ""} onClick={() => this.setState({ activeTab: "impfzenterTab" })}> Impfärzte</NavLink>
                </NavItem>
                <NavItem key={"impfungTab"}>
                    <NavLink className={this.state.activeTab == "impfungTab" ? "active" : ""} onClick={() => this.setState({ activeTab: "impfungTab" })}> Impfungen</NavLink>
                </NavItem>
                <NavItem key={"docTab"}>
                    <NavLink className={this.state.activeTab == "docTab" ? "active" : ""} onClick={() => this.setState({ activeTab: "docTab" })}> Dokumente</NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
                <TabPane key="persTab" tabId="persTab">
                    <h3>Ihre Personalien:&nbsp;</h3>
                    <Row>
                        <Col md={3}>
                            <LabelWithText label="Anrede" text={this.state.aktenzeichen?.mandant.herrFrau} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Titel" text={this.state.aktenzeichen?.mandant.titel} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Vorname" text={this.state.aktenzeichen?.mandant.vorname} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Nachname" text={this.state.aktenzeichen?.mandant.nachname} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <LabelWithText label="Strasse" text={this.state.aktenzeichen?.mandant.strasse} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Hausnummer" text={this.state.aktenzeichen?.mandant.hausnummer} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Postleitzahl" text={this.state.aktenzeichen?.mandant.plz} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Ort" text={this.state.aktenzeichen?.mandant.ort} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <LabelWithText label="Telefonnummer" text={this.state.aktenzeichen?.mandant.telefonnummer} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="Emailadresse" text={this.state.aktenzeichen?.mandant.emailadresse} />
                        </Col>
                        <Col md={3}>
                            <LabelWithText label="IBAN" text={this.state.aktenzeichen?.mandant.iban} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane key="impfzenterTab" tabId="impfzenterTab">
                    <h3>Erfasste Impfärzte:&nbsp;</h3>
                    <Row>
                        <Col md={4}>
                            <b>Name</b>
                        </Col>
                        <Col md={4}>
                            <b>Email</b>
                        </Col>
                        <Col md={4}>
                            <b>PLZ Ort</b>
                        </Col>
                    </Row>
                    {
                        this.state.aktenzeichen?.gegner.map((gegner, idx) => {
                            return <Row key={"overviewcenter" + idx}>
                                <Col md={4}>
                                    {gegner.vorname} {gegner.nachname}
                                </Col>
                                <Col md={4}>
                                    {gegner.emailadresse}
                                </Col>
                                <Col md={4}>
                                    {gegner.plz} {gegner.ort}
                                </Col>
                            </Row>
                        })
                    }
                </TabPane>
                <TabPane key="impfungTab" tabId="impfungTab">
                    <h3>Erfasste Impfungen:&nbsp;</h3>
                    <Row>
                        <Col md={6}>
                            <LabelWithText label="Geforderte Summe pro Impfung: " text={this.state.aktenzeichen?.schmerzensGeldProImpfung + " €"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <b>Datum</b>
                        </Col>
                        <Col md={3}>
                            <b>Impfstoff</b>
                        </Col>
                        <Col md={3}>
                            <b>Zertifikat ID</b>
                        </Col>
                        <Col md={3}>
                            <b>Impfarzt</b>
                        </Col>
                    </Row>
                    {
                        this.state.aktenzeichen?.impfungen.map((impfung, idx) => {
                            return <Row key={"overviewimpf" + idx}>
                                <Col md={3}>
                                    {impfung.impfDatum}
                                </Col>
                                <Col md={3}>
                                    {impfung.impfStoff}
                                </Col>
                                <Col md={3}>
                                    {impfung.impfZertifikatId}
                                </Col>
                                <Col md={3}>
                                    {this.state.aktenzeichen?.gegner.find(g => g.gegnerNr == impfung.gegnerNr)?.vorname} &nbsp;
                                    {this.state.aktenzeichen?.gegner.find(g => g.gegnerNr == impfung.gegnerNr)?.nachname}
                                </Col>
                            </Row>
                        })
                    }
                </TabPane>
                <TabPane key="docTab" tabId="docTab">
                    <h3>Dokumente zum Herunterladen</h3>
                    <h5>Dokumente markiert mit * müssen ausgedruckt, unterschrieben und eingescannt wieder hochgeladen werden.</h5>
                    {
                        this.state.aktenzeichen?.documents.map((doc) => {
                            return <Row>
                                <Col md={4}>
                                    {this.state.documentTypes.find((docType) => docType.documentName == doc.name)?.mandatoryUploadFile ? "*" : ""}{doc.name} :&nbsp;
                                </Col>
                                <Col md={8}>
                                    <a href="#" onClick={(e) => { this.downloadFile(doc); e.preventDefault(); }}>{doc.downloadFileName}<br /></a>
                                </Col>
                            </Row>

                        })
                    }

                    <h3>Dokumente hochladen nach signieren:&nbsp;</h3>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label for="docType">
                                    Dokument typ
                                </Label>
                            </Col>
                            <Col md={10}>
                                <Input
                                    id="docType"
                                    name="docType"
                                    type="select"
                                    value={this.state.selectedDocType}
                                    onChange={this.handleSelectionChange}
                                >
                                    {
                                        this.state.documentTypes.map((docType) => {
                                            if (docType.mandatoryUploadFile) {
                                                return <option value={docType.documentType}>{docType.documentName}</option>
                                            }
                                        })
                                    }
                                </Input>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label for="file">
                                    Dokument
                                </Label>
                            </Col>
                            <Col md={10}>
                                <Input id="file" type="file" onChange={this.handleFileSelected} >
                                </Input>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={() => this.uploadFile()}>Dokument hochladen</Button>
                    </FormGroup>
                </TabPane>
            </TabContent>
        </Container>
    }
}




interface AktenzeichenProperties {
    navigateFunction: NavigateFunction;
}

interface AktenzeichenState {
    aktenzeichen: Akte | undefined;
    documentTypes: MyDocumentType[];
    selectedFile: File | undefined;
    selectedDocType: string;
    paymentStati: PaymentStatus[];
    selectedPaymentStati: string;
    activeTab: string;
}
import { MyDocumentType, PaymentStatus } from "../Model/RegistrationModels";

export class ApiService {
    token: string = "";

    fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
        if (!init) {
            init = {};
        }

        init.mode = "cors";
        init.redirect = "manual";

        if (this.token.length > 0) {
            init.credentials = "include";
            
            init.headers = {
                ...init.headers,
                'Authorization': 'BEARER ' + this.token
            }
        }

        if (!input.toString().startsWith("http")) {
            var apiLocation = process.env.REACT_APP_API_LOCATION ?? "";
            input = apiLocation + input;
        }

        return fetch(input, init);
    }

    async GetDocumentTypes(): Promise<MyDocumentType[]> {
        var response = await apiService.fetch("/document/types");
        return response.json();
    }

    async GetPaymentStati(): Promise<PaymentStatus[]> {
        var response = await apiService.fetch("/aktenzeichen/payed/statuslist");
        return response.json();
    }

    async RequestOTP(aktenzeichen: string) {
        this.token = "";
        var formData = "aktenzeichen=" + encodeURIComponent(aktenzeichen);

        var response = await apiService.fetch("/authentication/sendpassword", {
            body: formData,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    }


    async adminRequestOTP(email: string) {
        this.token = "";
        var formData = "email=" + encodeURIComponent(email);

        var response = await apiService.fetch("/admincenter/sendpassword", {
            body: formData,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    }

    async RequestAktenkennzeichen(email: string) {
        this.token = "";

        var formData = "{\"emailaddress\":\"" + email + "\"}";

        var response = await apiService.fetch("/aktenzeichen", {
            body: formData,
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });
    }

    async Login(aktenzeichen: string, password: string): Promise<boolean> {
        this.token = "";

        var formData = "aktenzeichen=" + encodeURIComponent(aktenzeichen) + "&password=" + encodeURIComponent(password);
        
        var response = await apiService.fetch("/authentication/login", {
            body: formData,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.ok) {
            var result = await response.json()
            this.token = result.token;

            return true;
        }

        return false;
    }

    async AdminLogin(email: string, password: string): Promise<boolean> {
        this.token = "";

        var formData = "emailaddress=" + encodeURIComponent(email) + "&password=" + encodeURIComponent(password);

        var response = await apiService.fetch("/admincenter/login", {
            body: formData,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.ok) {
            var result = await response.json()
            this.token = result.token;

            return true;
        }

        return false;
    }
}

const apiService = new ApiService();

export default apiService;
import { Component } from "react";
import { Container } from "reactstrap";

export class Revolcationrules extends Component {

    render() {
        return <div className="card text-lg-start">
            <div className="card-header">
                <h2 className="text-center">
                    Widerrufsbelehrung<br/><br/>
                    bei außerhalb von Geschäftsräumen geschlossenen Verträgen<br/><br/>
                    Widerrufsbelehrung<br/><br/>
                    für ein Mandat bei Advocatur Dr. Frohnecke GmbH, Industriering 40, FL-9491 Ruggell<br/><br/>

                </h2>
            </div>
            <div className="card-body">
                <h3>Widerrufsrecht</h3>
                <p>
                Sie haben das Recht, binnen 14 Tagen ohne Angaben von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beginnt 14 Tage ab dem Tag des Vertragsschlusses.
                </p><p>
                Um Ihr Widerrufsrecht auszuüben müssen Sie uns,
            </p><p>
                Advocatur Dr. Frohnecke GmbH<br/>
                Industriering 40<br/>
                FL-9491 Ruggell<br/>
                <a href="mailto:widerruf@impfopferschutz.de">widerruf@impfopferschutz.de</a><br/>
            </p><p>
                mittels einer eindeutigen Erklärung, zum Beispiel einem mit der Post versandten Brief oder einer E-Mail, über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
            </p><p>
                Zur Wahrung der Frist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
                <h3>Folgen des Widerrufsrufs</h3>
                <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tage ab dem Tage zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden wir Ihnen wegen dieser Rückzahlung Entgelt berechnen.
                </p>
            </div>
            <div className="card-footer text-muted">
                12.11.2022
            </div>
        </div>
    }
}

import { Component } from "react";
import { Button, Col, Container, FormGroup, Input, InputGroup, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row, TabContent, TabPane, UncontrolledCollapse } from "reactstrap";
import { isNumber } from "util";
import { Akte, MyDocument, MyDocumentType, PaymentStatus } from "../Model/RegistrationModels";
import apiService from "../Service/ApiService";
import { Helpers } from "../Service/Helpers";
import { LabelWithText } from "./Helpers/LabelWithText";


export class Admincenter extends Component<AdmincenterProperties, AdmincenterState> {
    constructor(props: AdmincenterProperties) {
        super(props);

        this.state = {
            akten: [],
            documentTypes: [],
            selectedFile: undefined,
            selectedDocType: "",
            paymentStati: [],
            aktenCount: 0,
            currentPage: 0,
            activeTab: "",
            aktenkennzeichen: ""
        }
    }

    componentDidMount() {
        this.loadAll();
        this.loadDocType()
    }

    async loadDocType(){
        var docTypes = await apiService.GetDocumentTypes();
        if(docTypes.length > 0){
            this.setState(
                {
                    documentTypes: docTypes,
                    selectedDocType: docTypes.find(dt => dt.mandatoryUploadFile)?.documentType ?? ""
                }
            )
        }
    }

    async loadAll() {
        this.handlePagination(0);
    }


    async uploadFile() {
        if (!this.state.selectedFile) {
            alert("Bitte eine Datei auswählen.");
            return;
        }

        var formData = new FormData();
        formData.append("file", this.state.selectedFile!);
        formData.append("document_typ", this.state.selectedDocType);
        formData.append("aktenzeichen", this.state.activeTab)

        var result = await apiService.fetch("/admincenter/upload", {
            method: "POST",
            body: formData
        });

        if (result.ok) {
            alert(result.status +" : Dokument hochgeladen")
        } else {
            alert(result.status + " " + result.statusText)
        }
    }


    async downloadFile(file: MyDocument, aktenzeichen: string) {
        var response = await apiService.fetch("/admincenter/download", {
            method: "POST",
            body: '{ "downloadFilename": "' + file.downloadFileName + '", "aktenzeichen": "' + aktenzeichen + '"}',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        if (response.ok) {
            var fileName = file.downloadFileName;
            var blob = await response.blob();
            const newBlob = new Blob([blob], { type: 'application/pdf' });
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = fileName;
            link.click();

            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
        } else if (response.status == 401) {
            //this.props.navigateFunction("/anmelden");
        }
    }

    handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedDocType: e.currentTarget.value });
    }

    handleFileSelected = (evt: any) => {
        var file = evt.currentTarget.files[0];

        this.setState({
            selectedFile: file
        })
    }

    handleAkteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ aktenkennzeichen: e.currentTarget.value });
    }

    async handlePagination(page: number) {
        var count = 10;
        var start = page * count;

        var response = await apiService.fetch("/admincenter/alleakten", {
            method: "POST",
            body: '{"startseite":' + start + ', "anzahl_eintraege":10}',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        if (response.ok) {
            var akten: Akte[] = await response.json();
            var aktenCountResponse = await apiService.fetch("/admincenter/countakten");
            if (aktenCountResponse.ok) {
                var aktenCount = await aktenCountResponse.text();
                this.setState({ akten: akten.sort((a, b) => Helpers.stringsort(a.aktenZeichen, b.aktenZeichen)), aktenCount: parseInt(aktenCount), currentPage: page, activeTab: akten[0].aktenZeichen, aktenkennzeichen: "" });
            }
        }
    }

    getPaginationItems(numberOfPages: number) {

        var arr = [];
        for (var i = 0; i <= numberOfPages; i++) {
            arr.push(i);
        }

        return arr.map((_, index) => {
            return <PaginationItem active={this.state.currentPage == index}>
                <PaginationLink onClick={() => { this.handlePagination(index) }} >{index + 1}</PaginationLink>
            </PaginationItem>
        })
    }

    async loadAkte() {
        var response = await apiService.fetch("/admincenter/akte", {
            method: "POST",
            body: this.state.aktenkennzeichen,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        if (response.ok) {
            var akte: Akte = await response.json();
            this.setState({ akten: [akte], activeTab: akte.aktenZeichen, currentPage: -1 });
        }
    }


    render() {
        var numberOfPages = parseInt((this.state.aktenCount / 10).toString());


        return <Container>
            <h1>AdminCenter</h1>
            <Row>
                <Col md={3}>
                    <InputGroup>
                        <Input type="text" placeholder="Aktenzeichen" onChange={this.handleAkteChanged} value={this.state.aktenkennzeichen} />
                        <Button onClick={() => this.loadAkte()}>Laden</Button>
                    </InputGroup>
                </Col>
                <Col md={9}>
                    <Pagination>

                        <PaginationItem>
                            <PaginationLink onClick={() => { this.handlePagination(0) }} first ></PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currentPage == 0}>
                            <PaginationLink onClick={() => { this.handlePagination(this.state.currentPage - 1) }} previous ></PaginationLink>
                        </PaginationItem>
                        {
                            this.getPaginationItems(numberOfPages)
                        }
                        <PaginationItem disabled={this.state.currentPage == numberOfPages} >
                            <PaginationLink onClick={() => { this.handlePagination(this.state.currentPage + 1) }} next ></PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink onClick={() => { this.handlePagination(numberOfPages) }} last ></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </Col>
            </Row>

            <Nav tabs>
                {
                    this.state.akten.map(akte => {
                        return <NavItem key={"NAV" + akte.aktenZeichen}>
                            <NavLink className={this.state.activeTab == akte.aktenZeichen ? "active" : ""} onClick={() => this.setState({ activeTab: akte.aktenZeichen })}> {akte.aktenZeichen}</NavLink>
                        </NavItem>
                    })
                }
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
                {
                    this.state.akten.map((akte) => {
                        return <TabPane key={akte.aktenZeichen} tabId={akte.aktenZeichen}>
                            <Container>
                                <h1>Aktenkennzeichen {akte.aktenZeichen}</h1>
                                <h2>Übersicht:&nbsp;</h2>

                                <h3>Aktenstatus:&nbsp;</h3>
                                <Row>
                                    <Col md={3}>
                                        <LabelWithText label="Aktenzeichen" text={akte.aktenZeichen} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Status" text={akte.aktenStatus} />
                                    </Col>
                                    <Col md={6}>
                                        <LabelWithText label="Status Bezahlung Forderung" text={akte.payInfoText} />
                                    </Col>
                                </Row>

                                <h3>Personalien:&nbsp;</h3>
                                <Row>
                                    <Col md={3}>
                                        <LabelWithText label="Anrede" text={akte.mandant.herrFrau} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Titel" text={akte.mandant.titel} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Vorname" text={akte.mandant.vorname} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Nachname" text={akte.mandant.nachname} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <LabelWithText label="Strasse" text={akte.mandant.strasse} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Hausnummer" text={akte.mandant.hausnummer} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Postleitzahl" text={akte.mandant.plz} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Ort" text={akte.mandant.ort} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <LabelWithText label="Telefonnummer" text={akte.mandant.telefonnummer} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="Emailadresse" text={akte.mandant.emailadresse} />
                                    </Col>
                                    <Col md={3}>
                                        <LabelWithText label="IBAN" text={akte.mandant.iban} />
                                    </Col>
                                </Row>
                                <h3>Erfasste Impfärzte:&nbsp;</h3>
                                <Row>
                                    <Col md={4}>
                                        <b>Name</b>
                                    </Col>
                                    <Col md={4}>
                                        <b>Email</b>
                                    </Col>
                                    <Col md={4}>
                                        <b>PLZ Ort</b>
                                    </Col>
                                </Row>
                                {
                                    akte.gegner.map((gegner, idx) => {
                                        return <Row key={"overviewcenter" + idx}>
                                            <Col md={4}>
                                                {gegner.vorname} {gegner.nachname}
                                            </Col>
                                            <Col md={4}>
                                                {gegner.emailadresse}
                                            </Col>
                                            <Col md={4}>
                                                {gegner.plz} {gegner.ort}
                                            </Col>
                                        </Row>
                                    })
                                }

                                <h3>Erfasste Impfungen:&nbsp;</h3>
                                <Row>
                                    <Col md={6}>
                                        <LabelWithText label="Geforderte Summe pro Impfung: " text={akte.schmerzensGeldProImpfung + " €"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <b>Datum</b>
                                    </Col>
                                    <Col md={3}>
                                        <b>Impfstoff</b>
                                    </Col>
                                    <Col md={3}>
                                        <b>Zertifikat ID</b>
                                    </Col>
                                    <Col md={3}>
                                        <b>Impfarzt</b>
                                    </Col>
                                </Row>
                                {
                                    akte.impfungen.map((impfung, idx) => {
                                        return <Row key={"overviewimpf" + idx}>
                                            <Col md={3}>
                                                {impfung.impfDatum}
                                            </Col>
                                            <Col md={3}>
                                                {impfung.impfStoff}
                                            </Col>
                                            <Col md={3}>
                                                {impfung.impfZertifikatId}
                                            </Col>
                                            <Col md={3}>
                                                {akte.gegner.find(g => g.gegnerNr == impfung.gegnerNr)?.vorname} &nbsp;
                                                {akte.gegner.find(g => g.gegnerNr == impfung.gegnerNr)?.nachname}
                                            </Col>
                                        </Row>
                                    })
                                }
                                <h3>Dokumente zum Herunterladen</h3>
                                {
                                    akte.documents.map((doc) => {
                                        return <Row>
                                            <Col md={4}>
                                                {doc.name} :&nbsp;
                                            </Col>
                                            <Col md={4}>
                                                <a href="#" onClick={(e) => { this.downloadFile(doc, akte.aktenZeichen); e.preventDefault(); }}>{doc.downloadFileName}<br /></a>
                                            </Col>
                                        </Row>

                                    })
                                }

                                <h3>Dokumente hochladen nach signieren:&nbsp;</h3>
                                <FormGroup>
                                    <Row>
                                        <Col md={2}>
                                            <Label for="docType">
                                                Dokument typ
                                            </Label>
                                        </Col>
                                        <Col md={10}>
                                            <Input
                                                id="docType"
                                                name="docType"
                                                type="select"
                                                value={this.state.selectedDocType}
                                                onChange={this.handleSelectionChange}
                                            >
                                                {
                                                    this.state.documentTypes.map((docType) => {
                                                        if (docType.mandatoryUploadFile) {
                                                            return <option value={docType.documentType}>{docType.documentName}</option>
                                                        }
                                                    })
                                                }
                                            </Input>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md={2}>
                                            <Label for="file">
                                                Dokument
                                            </Label>
                                        </Col>
                                        <Col md={10}>
                                            <Input id="file" type="file" onChange={this.handleFileSelected2} >
                                            </Input>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Button onClick={() => this.uploadFile()}>Dokument hochladen</Button>
                                </FormGroup>
                            </Container>
                        </TabPane>
                    })
                }
            </TabContent>
        </Container>
    }

    handleFileSelected2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt == null) {
            return;
        }

        if (evt.currentTarget == null) {
            return;
        }

        if (evt.currentTarget.files == null) {
            return;
        }

        if (evt.currentTarget.files.length == 0) {
            return;
        }

        var file = evt.currentTarget.files[0];
        if (!file.name.endsWith(".pdf")) {
            alert("Es sind nur PDF Dateien erlaubt.");
            evt.preventDefault();
            return;
        }

        this.setState({
            selectedFile: file
        })
    }

}

interface AdmincenterProperties {

}

interface AdmincenterState {
    akten: Akte[];
    documentTypes: MyDocumentType[];
    selectedFile: File | undefined;
    selectedDocType: string;
    paymentStati: PaymentStatus[];
    aktenCount: number;
    currentPage: number;
    activeTab: string;
    aktenkennzeichen: string;
}
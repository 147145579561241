import React, { Component } from 'react';
import {
    Button,
    Col,
    Collapse,
    Container, Modal, ModalBody, ModalFooter, ModalHeader,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component<NavMenuProps, NavMenuState> {
    static displayName = NavMenu.name;

    constructor(props: NavMenuProps) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            collapsed: true,
            modal: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleModal(){
        this.setState({
            modal : !this.state.modal
                      })
    }

    render() {
        return <header style={{ backgroundColor: '#0D4691' }}>
            <Container>
                <Navbar style={{ backgroundColor: '#0D4691' }} className="navbarcontainer navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <NavbarBrand className="text-white" tag={Link} to="/">
                        <Container>
                            <Row>
                                <Col className='col-sm-3'><img src="/Images/LogoFF.svg" height="120" width="160" alt="FF" /></Col>
                                <Col><br/>Rechtsanwalt Dr. iur. Eberhard Frohnecke</Col>
                            </Row>
                            <Row>
                                <Col className="smallNaviText">Fachanwalt für gewerblichen Rechtsschutz und Fachanwalt für Versicherungsrecht</Col>
                            </Row>
                        </Container>
                        </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex headerWrapper" isOpen={!this.state.collapsed} navbar>
                        <Nav className="ms-auto">
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/anwalt">Ihr Anwalt</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/willkommen">So geht es</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/wastun">Was tun?</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/registrieren">Anspruch stellen</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-white" to="/anmelden">Anmelden</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} centered={true}>
                    <ModalHeader toggle={this.toggleModal}>Unterstützen Sie!</ModalHeader>
                    <ModalBody>
                        Bitte unterstützen Sie uns mit einer Spende für ein auch gesellschaftspolitisches Zeichen an: <br/>
                        Max von Breitenstein ProjektPartner GmbH <br/><br/>
                        IBAN: LI32 0880 0562 6124 4200 3. <br/><br/>
                        Vielen Dank!
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleModal}>
                            Schliessen
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </header>;
    }
}

interface NavMenuProps {

}

interface NavMenuState {
    collapsed: boolean;
    modal: boolean;
}
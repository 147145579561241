import React, { Component, FormEvent } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Col, Container, FormGroup, Input, InputGroup, Label, Row, UncontrolledCollapse } from 'reactstrap';
import apiService from '../Service/ApiService';

export class Login extends Component<LoginProperties, LoginState> {
    static displayName = Login.name;

    constructor(props: LoginProperties) {
        super(props);
        this.state = {
            aktenzeichen: props.fallnummer ?? "",
            password: "",
            loginSuccessful: true,
            otpSent: false,
            mail: "",
            adminemail: "",
            adminpassword: "",
            passwordRequested: false
        }
    }

    handleChange = (e: FormEvent<HTMLInputElement>) => {
        var state: any = this.state;
        state[e.currentTarget.name] = e.currentTarget.value;

        this.setState(state);
    }

    async requestPassword() {
        var result = await apiService.RequestOTP(this.state.aktenzeichen);
        this.setState({ otpSent: result, passwordRequested: result });
    }

    async requestAdminPassword() {
        var result = await apiService.adminRequestOTP(this.state.adminemail);
        this.setState({ otpSent: result, passwordRequested: result });
    }

    async login() {
        var successful = await apiService.Login(this.state.aktenzeichen, this.state.password);
        if (successful) {
            this.setState({ loginSuccessful: true });
            this.props.navigateFunction("/aktenzeichen");
        } else {
            this.setState({ loginSuccessful: false });
        }
    }
    async adminlogin() {
        var successful = await apiService.AdminLogin(this.state.adminemail, this.state.adminpassword);
        if (successful) {
            this.setState({ loginSuccessful: true });
            this.props.navigateFunction("/admincenter");
        } else {
            this.setState({ loginSuccessful: false });
        }
    }

    async mailAktenkennzeichen() {
        await apiService.RequestAktenkennzeichen("");
    }

    render() {
        return <div>
            <h1>Anmelden</h1>
            <Container>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="Aktenzeichen">
                                Passwort anfordern für Aktenzeichen
                            </Label>
                            <h3 hidden={!this.state.otpSent}>Es wurde ein Passwort an die im Aktenzeichen registrierten Email adresse versendet.</h3>
                            <InputGroup>

                                <Input
                                    id="aktenzeichen"
                                    name="aktenzeichen"
                                    placeholder="Aktenzeichen"
                                    type="text"
                                    value={this.state.aktenzeichen}
                                    onChange={this.handleChange}
                                />
                                <Button color="primary" hidden={this.state.otpSent} onClick={() => this.requestPassword()}>Password anfordern</Button>
                            </InputGroup>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Passwort"
                                type="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                            <Button disabled={!this.state.passwordRequested} color="primary" onClick={() => this.login()}>Anmelden</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row hidden={true}>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="mail">
                                Aktenzeichen anfordern für registrierte Emailadresse
                            </Label>
                            <h3 hidden={!this.state.otpSent}>Es wurde ein Passwort an die im Aktenzeichen registrierten Email adresse versendet.</h3>
                            <Input
                                id="mail"
                                name="mail"
                                placeholder="Email"
                                type="text"
                                onChange={this.handleChange}
                            />
                            <Button color="primary" onClick={() => this.mailAktenkennzeichen()}>Aktenkennzeichen anfordern</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Row>
                        <Col md={6}>
                            <Button
                                color="primary"
                                id="toggler"
                                style={{
                                    marginBottom: '1rem'
                                }}
                            >
                                Admin Login
                            </Button>
                        </Col>
                    </Row>
                    <UncontrolledCollapse toggler="#toggler">
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="Aktenzeichen">
                                        Passwort anfordern für Admin Login
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="adminemail"
                                            name="adminemail"
                                            placeholder="Admin Email"
                                            type="email"
                                            value={this.state.adminemail}
                                            onChange={this.handleChange}
                                        />
                                        <Button color="primary" onClick={() => this.requestAdminPassword()}>Password anfordern</Button>
                                    </InputGroup>
                                    <Input
                                        id="adminpassword"
                                        name="adminpassword"
                                        placeholder="Admin Passwort"
                                        type="password"
                                        value={this.state.adminpassword}
                                        onChange={this.handleChange}
                                    />
                                    <Button disabled={!this.state.passwordRequested} color="primary" onClick={() => this.adminlogin()}>Anmelden</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Row>
            </Container>

        </div>;
    }
}

interface LoginProperties {
    navigateFunction: NavigateFunction;
    fallnummer: string | undefined;
}

interface LoginState {
    aktenzeichen: string;
    password: string;
    loginSuccessful: boolean;
    otpSent: boolean;
    mail: string;
    adminemail: string;
    adminpassword: string;
    passwordRequested: boolean;
}
import { useNavigate, useParams } from "react-router-dom";
import { Login } from "./Login";

export function LoginWrapper() {
    let navigate = useNavigate();
    let { fallnummer } = useParams();

    return <Login navigateFunction={navigate} fallnummer={fallnummer} />;
}

export default LoginWrapper;
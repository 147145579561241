import { Component } from "react";
import { Container } from "reactstrap";

export class Impressum extends Component {
    render() {
        return <Container>
            <h1>Impressum</h1>
            <h2>Verantwortlich:</h2>
            <p>Advocatur Dr. Frohnecke GmbH<br />
                Industriering 40<br />
                FL-9491 Ruggell<br />
                T: +423220<br />
                M: <a href="mailto:post@frohnecke.li">post@frohnecke.li</a><br />
                eingetragen im HR im Fürstentum Liechtenstein: FL-0002.676.241-1<br />
            </p>
            <h2>V.i.S.d.P.:</h2>
            <p>Rechtsanwalt Dr. Eberhard Frohnecke,<br />
                geschäftsansässig:<br />
                Industriering 40<br />
                FL-9491 Ruggell<br />
            </p>
            <h2>Informationen DL-InfoV:</h2>
            <p>Rechtsanwalt Dr. Eberhard Frohnecke<br />
                Versicherungsnummer: T80 2717 790<br />
                bei der Allianz Suisse Versicherungs-Gesellschaft AG<br />
                Rechtsanwalt Dr. Frohnecke ist Pflichtversicherte bei der Allianz Suisse Versicherung-Gesellschaft AG;
                Versicherungsnummer: T80. 2717 790. Der Versicherungsschutz bezieht sich nicht auf Haftpflichtansprüche aus Tätigkeiten<br />
                a) über in anderen Staaten eingerichtete oder unterhaltene Kanzleien oder Büros,<br />
                b) im Zusammenhang mit der Beratung und Beschäftigung im außereuropäischen Recht und<br />
                c) des Rechtsanwalts vor außereuropäischen Gerichten.</p>

            <p>Dies bedeutet im Umkehrschluss, dass Rechtsanwalt Dr. Frohnecke über entsprechenden Versicherungsschutz auf dem Gebiet der Europäischen Union und dem Gebiet des Europäischen Wirtschaftsraum (EWR) verfügt, insoweit es sich auf dem Kontinent Europa befindet.</p>

            <p>Die Berufsbezeichnung Rechtsanwalt ist Rechtsanwalt Dr. Eberhard Frohnecke von der Bundesrepublik Deutschland verliehen worden (§ 2 I Nr. 6 DL-InfoV). Er verfügt über eine EWR-Zulassung zur Rechtsanwaltschaft bei der Liechtensteinischen Rechtsanwaltskammer.</p>

            <p>Es besteht die Möglichkeit eines außergerichtlichen Streitschlichtungsverfahrens vor der Rechtsanwaltskammer Oldenburg, Staugraben 5, 26122 Oldenburg und/oder der Liechtensteinischen Rechtsanwaltskammer, Heiligkreuz 43, FL-9490 Vaduz.</p>

            <p>Wesentliches Merkmal der hier angebotenen Dienstleistung ist Rechtsberatung und die Betreuung von Mandanten in Rechtsangelegenheiten außergerichtlich wie auch in Gerichtsverfahren (§ 2 I Nr. 10 DL-InfoV).</p>

            <p>Rechtsanwalt Dr. Eberhard Frohnecke ist Mitglied bei der Rechtsanwaltskammer in Oldenburg. Er ist ferner Mitglied bei der Liechtensteinischen Rechtsanwaltskammer.</p>

            <h2>Berufsrechtliche Regelungen</h2>
            <p>Rechtsanwalt Dr. Frohnecke unterliegt berufsrechtlichen Regelungen. Diese können betreffend der Bundesrepublik Deutschland auf der Homepage der Bundesrechtsanwaltskammer Deutschland, www.brak.de und betreffend der berufsrechtlichen Regelungen des Fürstentum Liechtenstein auf der Internetseite in der Liechtensteinischen Rechtsanwaltskammer www.rak.li eingesehen werden.</p>

            <h2>Firmierung</h2>
            <p>Advocatur Dr. Frohnecke GmbH</p>

            <h2>Finanzamt</h2>
            <p>Zuständig für die Advocatur Dr. Frohnecke GmbH in Ruggell ist die Steuerverwaltung Liechtenstein. Die Mehrwertsteuer-Identifikationsnummer MwSt.-Nr. lautet 62 301.</p>

            <h2>Haftung</h2>
            <p>Haftung ist nur insoweit ausgeschlossen, als diese nicht auf vorsätzlichem Handeln oder grober Fahrlässigkeit beruht.</p>
            <p>Hinweis: Links, die auf diese Seite verweisen, unterliegen nicht der Kontrolle von Rechtsanwalt Dr. Frohnecke. Wir bitten Sie daher, diese Links nicht mit dieser Seite in Verbindung zu bringen!</p>

            <h2>Informationen zur Online-Streitbeilegung</h2>
            <p>Die EU-Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die unter https://ec.europa.eu/consumers/odr aufrufbar ist.</p>
            <p>Rechtsanwalt Dr. Eberhard Frohnecke ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </Container>
    }
}